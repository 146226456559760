// extracted by mini-css-extract-plugin
export var branding = "header-module--branding--S74oq";
export var closebtn = "header-module--closebtn--cfXYy";
export var header = "header-module--header--rrXOn";
export var logo = "header-module--logo--voPyT";
export var nav = "header-module--nav--lGlUc";
export var navSearch = "header-module--navSearch--607gJ";
export var overlaycontent = "header-module--overlaycontent--enhUv";
export var root = "header-module--root--fVYhd";
export var rootSpacer = "header-module--rootSpacer--5LDo3";
export var searchButton = "header-module--searchButton--F54CH";
export var searchInput = "header-module--searchInput--aW8Wy";
export var showNav = "header-module--showNav--hpnIr";
export var showNavSearch = "header-module--showNavSearch--e8mfF";
export var toggleNavButton = "header-module--toggleNavButton--6GqI6";
export var wrapper = "header-module--wrapper--lsRR6";